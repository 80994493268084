<template>
  <div>
    <navbar title="我的收藏"></navbar>
    <div class="tablist">
      <div :class="type == 1 ? 'active' : ''" @click="cut(1)">新闻</div>
      <div :class="type == 2 ? 'active' : ''" @click="cut(2)">课程</div>
      <div :class="type == 3 ? 'active' : ''" @click="cut(3)">补贴地址</div>
    </div>
    <div class="box" v-if="list.length > 0">
      <div
        class="list"
        v-for="(item, index) in list"
        :key="index"
        @click="go(item)"
      >
        <div class="item">
          <div class="title">{{ item.name }}</div>
          <div class="time">
            <img src="../assets/time.png" />
            <div class="tiems">{{ item.time }}</div>
          </div>
        </div>
      </div>
    </div>
    <view class="tac" v-else>
      <view>暂无收藏</view>
    </view>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      type: 1,
      list: [],
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions(["getcollectionList"]),
    cut(type) {
      this.list = [];
      this.page = 1;
      this.type = type;
      this.getlist();
    },
    go(item) {
      if (this.type == 1) {
        this.$router.push({
          path: "/policydetails",
          query: {
            id: item.news_id,
          },
        });
      }
      if (this.type == 2) {
        this.$router.push({
          path: "/traindetails",
          query: {
            id: item.news_id,
          },
        });
      }
      if (this.type == 3) {
        this.$router.push({
          path: "/subsidy",
        });
      }
    },
    getlist() {
      this.getcollectionList({
        id: this.userinfo.id,
        type: this.type,
        page: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.SuccessCode == 200) {
          this.list = this.list.concat(res.ResponseBody);
          if (res.ResponseBody.length == 10) {
            ++this.page;
            this.getlist();
          }
        }
      });
    },
  },
  mounted() {
    this.getlist();
  },
};
</script>

<style lang='scss' scoped>
.tablist {
  background-color: #fff;
  display: flex;
  height: 0.6rem;
}

.tablist > div {
  text-align: center;
  font-size: 0.32rem;
  flex: 1;
}

.active {
  border-bottom: 0.06rem solid #5292f1;
}
.box {
  padding-top: 0.25rem;
}

.list {
  padding: 0.1rem;
}

.item {
  background-color: #fff;
  padding: 0.1rem;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.time {
  display: flex;
  margin-top: 0.25rem;
}

.time img {
  margin-top: 0.08rem;
  vertical-align: top;
  width: 0.26rem;
  height: 0.26rem;
}

.tiems {
  margin-left: 0.15rem;
  color: #cfcfcf;
  font-size: 0.32rem;
}

.comment {
  margin-left: 3rem;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cfcfcf;
}
</style>